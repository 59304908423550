


















































































import { Component, Vue } from "vue-property-decorator";
import { Message } from "element-ui";
import { Checkbox, Row, Col } from "element-ui";
import { ApiService } from "@/api";
import shared from "@/shared";
import { AppService } from "@/service/app-service";
import { Loading } from "element-ui";
import CryptoJS from 'crypto-js'
Vue.use(Checkbox);
Vue.use(Row);
Vue.use(Col);
@Component
export default class Login extends Vue {


  /**
  *  登录按钮loading 
  */
  buttonLoading:any = false

  $success = (Vue as any).prototype.$success

  $throw = (Vue as any).prototype.$throw
  
  /**
   * 表单状态数据
   *
   */
  public formState = {
    loginname: "",
    password: "",
    ssoUrl: 'albemarle.com',
    valistr:'',
    valistr_eamil: '',
    valistr_mobile:''
  };
  //控制用户 密码 button 成功失败的状态
  public loginnameflag = false;
  public ssoUrlflag = false;
  public passwordflag = false;
  public buttonflag = false;
  public checked = false;

      /**
    * 时间戳
    */
    public timestamp = "";

    /**
    * 登录展示
    */
    public loginshow: boolean = true;

    /**
    * 登录禁用
    */
    public logindisable: boolean = true;

    /**
    * 邮箱验证
    */
    public verify_email: boolean = false;

    /**
    * 手机验证
    */
    public verify_mobile: boolean = false;

        /**
         * 字符串验证
         */
    public verify_str: boolean = true;

        /**
         * 验证码图片
         */
    public verificationcodeImg:any = ""; 

    /**
    * 显示验证码 
    */
    public showVerification:boolean = false

    /**
    * 开启验证码时对登录按钮添加60px边距 
    */
    get loginshowStyle(){
      return this.verify_str? 'margin-top: 60px;' : null
    }

  /**
  * loading实例
  */
  public loadingInstance: any = []

  /**
  * 登录加密方法
  */
  public encryptionMethod:any = {
    md5(form:any) {
      return CryptoJS.MD5(form.loginname.toLowerCase() + '||' + form.password).toString()
    },
    AES128(str:any,iv:any,key:any){
      iv = CryptoJS.enc.Utf8.parse(iv)
      key = CryptoJS.enc.Utf8.parse(key)
      const encrypted = CryptoJS.AES.encrypt(
          CryptoJS.enc.Utf8.parse(str),key,{ 
              iv,
              mode:CryptoJS.mode.CBC,
              padding:CryptoJS.pad.Pkcs7
          }
      );
      return encrypted.toString()
    }
  }

  /**
  * 加密方式 
  */
  public encryption:any = {
    'login.encryption.method':'md5',
    'AES.key': '',
    'AES.iv': ''
  }

  /**
   * vue生命周期 -- mounted
   *
   */
  public mounted() {
    setTimeout(() => {
      const el = document.getElementById("app-root-loading-x");
      if (el) {
        el.style.display = "none";
      }
    }, 1000);
    this.getVerificationcode()
    const baseConfig:any = shared.getBaseConfig()
    Object.assign(this.encryption,baseConfig)
    this.showVerification = baseConfig['login.captcha.enabled']
    if(this.showVerification){
      this.getVerificationcode();
    }else{
      this.colseVerification()
    }
    this.handleSubmitSSO();
  }

  /**
  *  关闭验证码功能 
  */
  public colseVerification(){
    this.verify_str = false
    this.verify_email =false
    this.verify_mobile = false 
    this.loginshow = true
    this.logindisable = false
  }
  /**
  * 开启loading
  *
  * @memberof Login
  */
  public beginLoading(Id: string) {
    const selection: any = document.querySelector(`#${Id}`);
    if (!selection) {
      return
    }
    if (this.loadingInstance[Id]) {
      return
    }
    this.loadingInstance[Id] = Loading.service({
      fullscreen: false,
      target: selection,
      customClass: 'app-loading',
    });
    // 自定义loading元素
    const userEle = `<div class="app-loading-icon">
                          <div class="icon-content">
                              <div class="app-loading-icon-item active-color"></div>
                              <div class="app-loading-icon-item"></div>
                          </div>
                          <div class="icon-content">
                              <div class="app-loading-icon-item"></div>
                              <div class="app-loading-icon-item"></div>
                          </div>
                      </div>`;
    const loadingEle = selection.lastChild;
    if (loadingEle) {
      loadingEle.innerHTML = userEle;
    }

  }

  /**
  * 关闭loading
  *
  * @memberof Login
  */
  public endLoading(id: string) {
    if (this.loadingInstance[id]) {
      this.loadingInstance[id].close()
      this.loadingInstance[id] = null
    }
  }
  public handleSubmitSSO() {
    if (this.formState.ssoUrl == '') {
      this.ssoUrlflag = true
    } else {
      if (this.$route.query.code == 'login') {
        console.log('德勤登录')
      } else {
        const post: Promise<any> = ApiService.getInstance().LoginSSO({
          emailDomainName: this.formState.ssoUrl
        });
        post.then((res: any) => {
          localStorage.setItem('ssoToUrl', this.formState.ssoUrl);
          window.location.href = res.data.redirectUrl
        }).catch((err: any) => {
          Message.error((err as any)?.response?.data?.message);
        })
      }
    }
  }

  /**
  * 获取验证码
  */
   public getVerificationcode() {
    this.timestamp = new Date().getTime() + "";
    // http://default.digital-tsuite.com.cn/gateway/base/captchaCtrl/222/generate.dtt
    this.verificationcodeImg = (window as any).Environment.baseServiceUrl + "/captchaCtrl/" + this.timestamp + "/generate.dtt";
  }

     /**
   * 邮箱校验
   *
   * @memberof Login
   */
   public validateMailMfa(): void {
        if(this.formState.valistr_eamil.trim() == ""){
            this.$throw("请输入验证码！");
            return;
        }
        //captchaCtrl/{username}/getMfaType.dtt
        const validateMailMfa: Promise<any> = ApiService.getInstance().validateMailMfa(this.formState.loginname,this.formState.valistr_eamil);  
        validateMailMfa.then((response: any) => {
            if(response.data.result){
                this.login();
            }else{
                this.$throw("验证码输入错误！");
            }
        }).catch(() => {
            this.$throw("请求异常！");
        })
   }

   /**
   * 短信校验
   *
   * @memberof Login
   */
   public validateSmsMfa(): void {
        //captchaCtrl/{username}/getMfaType.dtt
        if(this.formState.valistr_mobile.trim() == ""){
            this.$throw("请输入验证码！");
            return;
        }
        const validateSmsMfa: Promise<any> = ApiService.getInstance().validateSmsMfa(this.formState.loginname,this.formState.valistr_mobile);  
        validateSmsMfa.then((response: any) => {
            if(response.data.result){
                this.login();
            }else{
                this.$throw("验证码输入错误！");
            }
        }).catch(() => {
            this.$throw("请求异常！");
        })
   }

   /**
   * 用户验证
   *
   * @memberof Login
   */
   public verifyUser(): void {
        //captchaCtrl/{username}/getMfaType.dtt
        const getMfaType: Promise<any> = ApiService.getInstance().getMfaType(this.formState.loginname);  
        getMfaType.then((response: any) => {
            console.log(response)
            if(response.data.result == "email"){
                const sendMailMfa: Promise<any> = ApiService.getInstance().sendMailMfa(this.formState.loginname);  
                sendMailMfa.then((response: any) => {  
                   
                    if(response.data.success){
                        this.loginshow = false; 
                        this.logindisable = true; 
                        this.verify_email = true;
                        this.verify_str = false;
                        this.$success("验证码已发送至您的邮箱，请查收！")
                    }else{
                        this.loginshow = true; 
                        this.logindisable = true; 
                        this.verify_email = false;
                        this.verify_str = false;
                        this.$throw("发送失败！");
                    }
                }).catch(() => {
                    this.$throw("请求异常！");
                })   
            }else if(response.data.result == "mobile"){
                const sendSmsMfa: Promise<any> = ApiService.getInstance().sendSmsMfa(this.formState.loginname);  
                sendSmsMfa.then((response: any) => {   
                    if(response.data.success){
                        this.$success("验证码已发送至您的手机，请查收！")
                        this.loginshow = false;
                        this.logindisable = true; 
                        this.verify_mobile = true;
                        this.verify_str = false;
                    }else{
                        this.loginshow = true;
                        this.logindisable = true; 
                        this.verify_mobile = false;
                        this.verify_str = false;
                        this.$throw("发送失败！");
                    }
                }).catch((err:any) => {
                    console.log(err)
                    this.$throw("请求异常！");
                }) 
            }else{
                this.$success("验证通过,请点击登录！")
                // 验证通过后隐藏验证码
                this.verify_str = false
                //未开启MFA 正常登录
                this.logindisable = false; 
            }
        }).catch((err:any) => {
            console.log(err)
            this.$throw("请求异常！");
            if(this.verify_str){
                this.getVerificationcode();
            }
        })
   }

  /**
   * 提交表单数据
   *
   */
  public async handleSubmit() {
    //判断用户名
    if (this.formState.loginname == '') {
      this.loginnameflag = true
    }
    //判断密码
    if (this.formState.password == '') {
      this.passwordflag = true
    }
    let checkvalidate = false;
      if(this.formState.valistr.trim() == ""){
            this.getVerificationcode();
            this.$throw("请填写图片图形验证码！");
            return;
      }
      const checkvalistr: Promise<any> = ApiService.getInstance().checkvalistr(this.formState,this.timestamp);  
      checkvalistr.then((response: any) => {
        console.log(response)
            checkvalidate = response.data;
            if(checkvalidate){
                this.verifyUser();
                // this.getVerificationcode();
            }else{
                this.$throw("图形验证码错误！");
                this.getVerificationcode();
            }
      }).catch((error: any) => {
        console.log(error)
        this.$throw("网络异常！");
      })
  }
  
  /**
  * 登录处理 
  */
  public login(){
    //判断用户名
    if (this.formState.loginname == '') {
      this.loginnameflag = true
    }else{
      this.loginnameflag = false
    }
    //判断密码
    if (this.formState.password == '') {
      this.passwordflag = true
    }else{
      this.passwordflag = false
    }
    if(this.loginnameflag || this.passwordflag){
      return
    }
    this.beginLoading('login-card-button')
      this.buttonLoading = true
      AppService.getInstance().clearAppData();
      let form_MD5 = { ...this.formState }
      // form_MD5.password = CryptoJS.MD5(form_MD5.loginname.toLowerCase() + '||' + form_MD5.password).toString()
      if(this.encryption['login.encryption.method'] === 'md5'){
        form_MD5.password = this.encryptionMethod.md5(form_MD5)
      }else{
        form_MD5.password = this.encryptionMethod.AES128(form_MD5.password,this.encryption['AES.iv'],this.encryption['AES.key'])
      }
      const post: Promise<any> = ApiService.getInstance().Login(form_MD5);
      post.then((res: any) => {
        const { data } = res;
        this.endLoading('login-card-button')
        if (data && data.token) {
          localStorage.setItem("ibzuaa-token", data.token);
          localStorage.setItem("loginname", this.formState.loginname);
          if (data && data.user) {
            localStorage.setItem("ibzuaa-user", JSON.stringify(data.user));
          }
          if (data && data.expirdate) {
            localStorage.setItem(
              "ibzuaa-expired",
              new Date(data.expirdate).getTime().toString()
            );
          }
          this.getOpenMymenus()
          localStorage.setItem('loginToUser', 'login');
          localStorage.removeItem('ssoToUrl');
          localStorage.removeItem('ssoUrlOld');
          const url: any = this.$route.query.redirect ? this.$route.query.redirect : '/';
          this.$router.push({ path: url });
        }
      }).catch((err: any) => {
        this.buttonLoading = false
        if(this.showVerification){
          this.verify_str = true
          this.logindisable = true; 
        }
        this.endLoading('login-card-button')
        Message.error((err as any)?.data?.message);
        if(this.verify_str){
          this.getVerificationcode();
        }
      })
  }

  /**
  * 是否开启我的菜单接口
  */
  public getOpenMymenus() {
    ApiService.getInstance()
      .getisOpenMymenus()
      .then((res: any) => {
        shared.setAppMemu(res.data.result == 1 ? false : true)
      }).catch((error: any) => {
        shared.setAppMemu(true)
      });
  } 
}
